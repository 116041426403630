export default defineAppConfig({
  ui: {
    primary: "teal",
    gray: "zinc",
    select: {
      base: "min-w-44"
    },
    table: {
      base: "relative",
      thead: "bg-background/75 backdrop-blur sticky top-0 z-10",
    },
    page: {
      card: {
        wrapper:
          "cursor-pointer hover:ring-2 hover:ring-primary-500 dark:hover:ring-primary-400 hover:bg-gray-100/50 dark:hover:bg-gray-800/50",
      },
    },
    dashboard: {
      modal: {
        height: "min-h-dvh h-full md:h-fit md:min-h-min",
        width: "sm:max-w-md"
      },
    }
  },
});
