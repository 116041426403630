<script setup lang="ts">
const store = useStore();
const colorMode = useColorMode();
const color = computed(() =>
  colorMode.value === "dark" ? "#18181b" : "white"
);

if (import.meta.client) {
  store.init();

  const socket = new WebSocket(wsUrl + "/server/status")

  socket.onopen = function () {
    if (store.user && store.user.id) {
      socket.send(JSON.stringify({
        type: "online",
        data: store.user.id,
      }));
    }
  };

  socket.onmessage = function (event) {
    if (event.data === "lockdown") {
      window.location.reload();
      return
    }

    let data = JSON.parse(event.data);
    if (data.type === "online") {
      store.loadOnlineUsers(data.data);
    }
  };

  socket.onerror = function (event) {
    socket.close();
  };
}

useHead({
  meta: [
    { charset: "utf-8" },
    { name: "viewport", content: "width=device-width, initial-scale=1" },
    { key: "theme-color", name: "theme-color", content: color },
  ],
  link: [{ rel: "icon", href: "/favicon.ico" }],
  htmlAttrs: {
    lang: "ro",
  },
});

useSeoMeta({
  title: "Platforma de administrare - alexagroup",
  description: "Platforma de administrare - alexagroup",
});
</script>

<template>
  <div>
    <VitePwaManifest />

    <NuxtLayout />

    <UNotifications />
    <LazyUModals />
  </div>
</template>

<style>
.page-enter-active,
.page-leave-active {
  transition: all 0.1s;
}

.page-enter-from,
.page-leave-to {
  opacity: 0;
  filter: blur(5px);
}

.layout-enter-active,
.layout-leave-active {
  transition: all 0.2s;
}

.layout-enter-from {
  opacity: 0;
  transform: translateX(-20px);
}

.layout-leave-to {
  opacity: 0;
  transform: translateX(20px);
}
</style>
